<template>
  <div class="main" data-title="客户档案" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">客户档案</h2>
      </div>
      <div class="head-search">
        <search-customer type="data"></search-customer>
      </div>
      <div class="head-tabs">
        <div
          :class="{curr :$route.query.type=='list'}"
          @click="$router.push({ query: { type: 'list' } })"
          class="tab"
        >所有客户</div>
        <div
          :class="{curr :$route.query.type=='vip'}"
          @click="$router.push({ query: { type: 'vip' } })"
          class="tab"
        >重点客户</div>
        <div
          :class="{curr :$route.query.type=='failed'}"
          @click="$router.push({ query: { type: 'failed' } })"
          class="tab"
        >战败客户</div>
        <div
          :class="{curr :$route.query.type=='succeed'}"
          @click="$router.push({ query: { type: 'succeed' } })"
          class="tab"
        >交车客户</div>
      </div>
    </div>
    <el-card class="main-content register-box" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">
          客户列表
          <span>（{{total}}）</span>
        </div>
        <div class="jue-card-btns">
          <el-button
            @click="$router.push({name: 'customer-register'})"
            class="btn"
            icon="el-icon-edit"
            type="primary"
            v-has="'income-create'"
          >客户登记</el-button>
          <el-dropdown v-if="multipleSelection.length > 0">
            <el-button class="btn" plain>
              批量操作
              <i class="el-icon-arrow-down"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>设为重点客户</el-dropdown-item> -->
              <el-dropdown-item @click.native="changeSeller" v-has="'client-change_seller'">更换销售顾问</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span class="jue-divider"></span>
          <export-data :searchKey = seachKey></export-data>
        </div>
      </div>

      <div class="no-data" v-if="total == 0">暂无数据</div>
      <div class="table-wrapper" v-else>
        <el-table
          :data="listData"
          :style="'width: '+$store.state.tableWidth"
          @selection-change="selectionChange"
          class="table"
        >
          <el-table-column fixed type="selection" width="55"></el-table-column>
          <el-table-column fixed label="客户姓名" prop="realname" width="110">
            <template slot-scope="scope">
              {{scope.row.realname}}
              <span
                style="color: #f56c6c;"
                v-if="scope.row.client_level == 22"
              >战败</span>
              <span style="color: #409EFF;" v-if="scope.row.client_level == 184">成交</span>
            </template>
          </el-table-column>
          <el-table-column fixed label="手机电话" prop="mobile" width="120"></el-table-column>
          <el-table-column label="进店日期" prop="income_date" width="100"></el-table-column>
          <el-table-column label="客户级别" prop="client_level" width="100">
            <template slot-scope="scope">
              <span
                v-if="$store.state.cfgData[scope.row.client_level] != undefined"
              >{{ $store.state.cfgData[scope.row.client_level][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="跟踪次数" prop="trace_count" width="120"></el-table-column>
          <el-table-column label="邀约到店" width="120">
            <template slot-scope="scope">{{scope.row.is_invite_income == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="最近跟踪时间" prop="last_trace_date" width="130">
            <template
              slot-scope="scope"
            >{{scope.row.last_trace_date == "" ? "无跟踪记录" : scope.row.last_trace_date}}</template>
          </el-table-column>
          <el-table-column label="销售顾问" width="100">
            <template slot-scope="scope">{{$store.state.userList[scope.row.seller_uid][0]}}</template>
          </el-table-column>
          <el-table-column label="来电/店" width="100">
            <template slot-scope="scope">{{scope.row.income_type == '1' ? '来店' : '来电'}}</template>
          </el-table-column>
          <el-table-column label="来访时间段" prop="income_time_section" width="120">
            <template slot-scope="scope">
              <span
                v-if="scope.row.income_time_section"
              >{{ $store.state.cfgData[scope.row.income_time_section][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="进离店时间" width="120">
            <template slot-scope="scope">
              <span>{{scope.row.income_time_begin}}</span> -
              <span>{{scope.row.income_time_end}}</span>
            </template>
          </el-table-column>
          <el-table-column label="留店时长" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.income_time_duration}}</span>分钟
            </template>
          </el-table-column>
          <el-table-column label="来店人数" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.income_number}}</span>人
            </template>
          </el-table-column>
          <el-table-column label="客户类型" prop="client_type" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.client_type"
              >{{ $store.state.cfgData[scope.row.client_type][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="微信号" width="120">
            <template slot-scope="scope">
              <span>{{scope.row.wechat}}</span>
            </template>
          </el-table-column>
          <el-table-column label="性别">
            <template slot-scope="scope">{{scope.row.sex == '1' ? '男' : '女'}}</template>
          </el-table-column>
          <el-table-column label="客户属性" width="100">
            <template slot-scope="scope">{{scope.row.is_first_income == '1' ? '首次' : '再次'}}</template>
          </el-table-column>
          <el-table-column label="区域" prop="region">
            <template slot-scope="scope">
              <span v-if="scope.row.region != 0">{{ $store.state.cfgData[scope.row.region][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否入座" prop="has_seat" width="100">
            <template slot-scope="scope">{{scope.row.has_seat == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="是否报价" prop="has_offer" width="100">
            <template slot-scope="scope">{{scope.row.has_offer == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="是否重点客户" prop="is_vip" width="130">
            <template slot-scope="scope">{{scope.row.is_vip == '1' ? '是' : '否'}}</template>
          </el-table-column>

          <el-table-column label="是否试驾" prop="has_trial" width="100">
            <template slot-scope="scope">{{scope.row.has_trial == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="现提车辆" prop="has_pick" width="100">
            <template slot-scope="scope">{{scope.row.has_pick == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="试驾备注" show-overflow-tooltip width="100">
            <template slot-scope="scope">
              <span>{{scope.row.trial_memo}}</span>
            </template>
          </el-table-column>

          <el-table-column label="来店目的" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.income_purpose"
              >{{ $store.state.cfgData[scope.row.income_purpose][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="意向车型" width="250">
            <template slot-scope="scope">
              <span
                v-if="$store.state.cfgData[scope.row.target_model] != undefined"
              >{{ $store.state.cfgData[scope.row.target_model][0] }}</span>
              <span
                v-if="$store.state.cfgData[scope.row.attribute] != undefined"
              >· {{ $store.state.cfgData[scope.row.attribute][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="变速箱类型" width="120">
            <template slot-scope="scope">
              <span
                v-if="scope.row.transmission"
              >{{ $store.state.cfgData[scope.row.transmission][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="需求排量" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.displacement"
              >{{ $store.state.cfgData[scope.row.displacement][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="外观颜色" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.outer_color"
              >{{ $store.state.cfgData[scope.row.outer_color][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="内饰颜色" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.inner_color"
              >{{ $store.state.cfgData[scope.row.inner_color][0] }}</span>
            </template>
          </el-table-column>

          <el-table-column label="线索渠道" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.clue_source"
              >{{ $store.state.cfgData[scope.row.clue_source][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="营销分析" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.marketing_analysis"
              >{{ $store.state.cfgData[scope.row.marketing_analysis][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="客户类别" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.client_cate"
              >{{ $store.state.cfgData[scope.row.client_cate][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="客户有效性" width="120">
            <template slot-scope="scope">{{scope.row.has_effective == '1' ? '有效' : '无效'}}</template>
          </el-table-column>
          <el-table-column label="购车类型" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.buy_type">{{ $store.state.cfgData[scope.row.buy_type][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="二手车型" prop="used_model" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.used_model}}</span>
            </template>
          </el-table-column>
          <el-table-column label="二手车到店" prop="used_arrived" width="120">
            <template slot-scope="scope">{{scope.row.used_arrived == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="是否勘察" prop="used_surveyed" width="100">
            <template slot-scope="scope">{{scope.row.used_surveyed == '1' ? '是' : '否'}}</template>
          </el-table-column>
          <el-table-column label="评估师" prop="valuator" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.valuator}}</span>
            </template>
          </el-table-column>
          <el-table-column label="简述" prop="demand_memo" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.demand_memo}}</span>
            </template>
          </el-table-column>
          <el-table-column label="促进结果" prop="trace_status" width="100">
            <template slot-scope="scope">
              <span
                v-if="$store.state.cfgData[scope.row.trace_status] != undefined"
              >{{ $store.state.cfgData[scope.row.trace_status][0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="跟踪描述" prop="trace_memo" width="180" show-overflow-tooltip></el-table-column>

          <el-table-column label="订车日期" prop="book_date" width="100"></el-table-column>
          <el-table-column label="交车日期" prop="deliver_date" width="100"></el-table-column>
          <el-table-column label="战败日期" prop="fall_date" width="100"></el-table-column>
          <el-table-column label="失控日期" prop="lose_date" width="100"></el-table-column>

          <el-table-column fixed="right" label="操作" width="240px">
            <template slot-scope="scope">
              <el-button @click="showTimeline(scope.row.client_id)" size="small" type="text">客户轨迹</el-button>
              <el-button
                @click="$router.push({ name: 'customer-tracking', query: {client_id: scope.row.client_id}})"
                size="small"
                type="text"
                v-has="'trace-create'"
              >跟踪</el-button>
              <span class="jue-divider" v-has="'trace-create'"></span>
              <el-button
                @click="view(scope.row.client_id)"
                size="small"
                type="text"
                v-has="'client-detail'"
              >查看</el-button>
              <span class="jue-divider" v-has="'client-set_vip'"></span>
              <el-button
                @click="setVip(scope.row)"
                size="small"
                type="text"
                v-has="'client-set_vip'"
                v-if="scope.row.is_vip == 1"
              >取消重点客户</el-button>
              <el-button
                @click="setVip(scope.row)"
                size="small"
                type="text"
                v-else
                v-has="'client-set_vip'"
              >设为重点客户</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            :page-size="seachKey.count"
            :total="total"
            @current-change="handleCurrentChange"
            background
            layout="prev, pager, next"
          ></el-pagination>
        </div>
      </div>
    </el-card>
    <change-seller @getListData="getListData(1)" ref="changeSeller"></change-seller>
    <client-detail ref="clientDetail"></client-detail>
    <time-line ref="timeline"></time-line>
  </div>
</template>
<script>
import ChangeSeller from '@/components/ChangeSeller.vue'
import ClientDetail from '@/components/ClientDetail.vue'
import TimeLine from '@/components/TimeLine.vue'
import SearchCustomer from '@/components/customer/SearchCustomer.vue'
import ExportData from '@/components/ExportExcel/ExportData.vue'
export default {
  components: {
    ChangeSeller,
    ClientDetail,
    ExportData,
    TimeLine,
    SearchCustomer
  },
  data() {
    return {
      listData: [],
      total: 0,
      all_total: 0,
      vip_total: 0,
      fall_total: 0,
      delivery_total: 0,
      multipleSelection: [],
      invite_data: [],
      options: {},
      car_type: '',
      seachKey: {
        page: 1,
        count: 15
      }
    }
  },
  mounted() {
    if (this.$route.query.type == 'vip') {
      this.seachKey.is_vip = 1
    }

    if (this.$route.query.type == 'failed') {
      this.seachKey.client_level = 22
    }
    if (this.$route.query.type == 'succeed') {
      this.seachKey.client_level = 184
    }
    this.init()
  },
  methods: {
    showTimeline(client_id) {
      this.$refs.timeline.view(client_id)
    },
    view(id) {
      this.$refs.clientDetail.view(id)
    },
    init() {
      if (!this.$route.query.type) {
        this.$router.push({ query: { type: 'list' } })
      }

      //获取数据
      this.getListData(1)
    },
    //点击分页
    handleCurrentChange(val) {
      this.getListData(val)
    },

    //设置重点客户
    setVip(data) {
      let txt = data.is_vip == '1' ? '取消' : '设置'
      let val = data.is_vip == '1' ? 2 : 1
      this.$confirm(
        '此操作将' + txt + data.realname + '为重点客户，是否继续',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$axios
            .post('/client/set_vip', {
              client_id: data.client_id,
              is_vip: val
            })
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.getListData(1)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch()
    },

    setSearchKey(seachKey) {
      this.seachKey = seachKey
      this.getListData(1)
    },

    //获取列表数据
    getListData(page) {
      this.seachKey.page = page
      this.$axios.post('/client/lists', this.seachKey).then(res => {
        if (res.data.code == 200) {
          this.listData = res.data.data.list
          this.total = res.data.data.total
          this.all_total = res.data.data.all_total
          this.vip_total = res.data.data.vip_total
          this.fall_total = res.data.data.fall_total
          this.delivery_total = res.data.data.delivery_total
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    //多选
    selectionChange(rows) {
      this.multipleSelection = rows
    },
    //变更销售顾问
    changeSeller() {
      this.$refs.changeSeller.initData(this.multipleSelection)
    }
  },
  watch: {
    '$route.query.type': function() {
      if (this.$route.query.type == 'list') {
        delete this.seachKey.is_vip
        delete this.seachKey.client_level
      }
      if (this.$route.query.type == 'vip') {
        this.seachKey.is_vip = 1
        delete this.seachKey.client_level
      }

      if (this.$route.query.type == 'failed') {
        delete this.seachKey.is_vip
        this.seachKey.client_level = 22
      }
      if (this.$route.query.type == 'succeed') {
        delete this.seachKey.is_vip
        this.seachKey.client_level = 184
      }
      this.getListData(1)
    }
  }
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
}

.footer {
  text-align: right;
  padding: 20px 0;
}

.form {
  /deep/ .el-form-item__label {
    padding: 0;
  }
  /deep/ .el-select,
  /deep/ .el-date-editor {
    width: 100%;
  }
}
</style>